import { Component, HostBinding, Input } from '@angular/core'
import { IconComponent } from 'src/app/elements/icon/icon.component'

@Component({
  standalone: true,
  selector: '[edButton]',
  templateUrl: './button.component.html',
  imports: [
    IconComponent,
  ],
  styleUrl: './button.component.sass',
})
export class ButtonComponent {
  @HostBinding('class') get classes () {
    const classes = [
      'ed-button',
      `e--theme-${this.options ? (this.options?.theme ?? 'default') : 'default'}`,
      `e--width-${this.options ? (this.options?.width ?? 'full') : 'full'}`,
    ]

    if (this.options && this.options.loading) {
      classes.push('e--loading')
    }

    if (this.options && this.options.disabled) {
      classes.push('e--disabled')
    }

    return classes
  }

  @Input({ required: false, alias: 'edButton' }) options?: {
    theme?: 'secondary' | 'default' | 'light',
    width?: 'full' | 'content',
    disabled?: boolean,
    loading?: boolean,
  } | ''
}
